import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
} from './utils';
import { DropDownService } from './backend/services/dropdown.service';
import { MenusService } from './backend/services/menus.service';
import { UsersService } from './backend/services/users.service';
import { UtilitiesService } from './backend/services/utilities.service';
import { DropDownApi } from './backend/api/dropdown.api';
import { HttpService } from './backend/api/http.service';

import { MenusApi } from './backend/api/menus.api';
import { UsersApi } from './backend/api/users.api';
import { UtilitiesApi } from './backend/api/utilities.api';
import { InitUserService } from '../@theme/services/init-user.service';
import { ReportService } from './backend/services/report.service';
import { ReportApi } from './backend/api/report.api';


const DATA_API = [DropDownApi, MenusApi, UsersApi, UtilitiesApi]

const DATA_SERVICES = [HttpService, DropDownService, MenusService, UsersService, UtilitiesService, InitUserService, ReportService, ReportApi];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...DATA_API,
  ...DATA_SERVICES,

  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
