import { ModuleWithProviders, NgModule } from '@angular/core';
import { ThemeModule } from '../@theme/theme.module';
import { NbCardModule, NbLayoutModule } from '@nebular/theme';
import { AuthPipe } from './auth.pipe';
import { CommonModule } from '@angular/common';
import { NbAuthJWTInterceptor, NbAuthModule, NbTokenLocalStorage, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { IdentityADB2CAuthStrategy } from './identity.auth.strategy';
import { HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { NbRoleProvider } from '@nebular/security';
import { RoleProvider } from './role.provider';
import { NbSecurityModule } from '@nebular/security'
import { AccessControlList } from './access-control-list';
import { nbAuthStrategyOptions } from './auth-strategy-options';

const PIPES = [AuthPipe];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
  return ['/auth/login', '/auth/sign-up', '/auth/request-pass', '/auth/refresh-token']
    .some(url => req.url.includes(url));
}

@NgModule({
  imports: [
    CommonModule,
    //AuthRoutingModule,
    NbAuthModule.forRoot({
      strategies: [
        IdentityADB2CAuthStrategy.setup(nbAuthStrategyOptions),
      ],
    }),
    NbCardModule,
    NbLayoutModule,
    ThemeModule,
    NbSecurityModule.forRoot({
      accessControl: AccessControlList
    }),
  ],
  declarations: [
    //AuthComponent,
    ...PIPES,
  ],
  exports: [...PIPES],
  providers: [
    {
      provide: NbRoleProvider, useClass: RoleProvider,
    },
    {
      provide: NbTokenLocalStorage, useClass: NbTokenLocalStorage,
    }
  ],

})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        IdentityADB2CAuthStrategy,
        { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest },
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    };
  }
}
