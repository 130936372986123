import { Component, OnInit, ChangeDetectionStrategy,Input, Output, EventEmitter} from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'jcms-error',
  templateUrl: './jcms-error.component.html',
  styleUrls: ['./jcms-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JCMSErrorComponent implements OnInit {
 
  @Input() buttonText:string;
  @Input() buttonUrl:string;
  @Input() buttonIcon:string;
  @Input() title:string;
  @Input() description:string;
  @Input() note:string;

  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClick(): void 
  {
    this.router.navigate([this.buttonUrl]);
  }

}
