import { NbOAuth2AuthStrategyOptions } from "@nebular/auth";
import { AuthIdentityToken } from "./identity.auth.strategy";
import { environment } from "../../environments/environment";

export const nbAuthStrategyOptions: NbOAuth2AuthStrategyOptions = {
  name: 'identity',
  clientId: environment.clientId,
  clientSecret: '',
  defaultErrors: ['Something went wrong, please try again.'],
  authorize: {
    requireValidToken: true,
    endpoint: environment.identityUrl + 'connect/authorize',
    responseType: 'id_token',
    scope: environment.scope,
    redirectUri: environment.redirectUrl,
    params: {
      'response_type': 'id_token token',
      'nonce': (Date.now() + "" + Math.random()).replace(".", ""),
      'state': (Date.now() + "" + Math.random()).replace(".", ""),
    },
  },
  token: {
    class: AuthIdentityToken,
  },
  redirect: {
    success: '/pages',
  },
}
