import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class ReportApi {
  private readonly apiController: string = 'report';

  constructor(private api: HttpService) { }


  getEstatements(startDate, endDate,locationCode, sinceLaunch,locationType,conferenceTitleId): Observable<any> {
    //let payload = {startDate:startDate, endDate:endDate,locationCode:locationCode};
    const uri = `${this.apiController}/estatements/${startDate}/${endDate}/${locationCode}/${sinceLaunch}/${locationType}/${conferenceTitleId}`;
    return this.api.get(uri);
  }

  getEstatementsByLanguage(startDate, endDate,locationCode, sinceLaunch,locationType,conferenceTitleId): Observable<any> {
    //let payload = {"startDate":startDate, "endDate":endDate,"locationCode":locationCode};
    const uri = `${this.apiController}/estatementsByLanguage/${startDate}/${endDate}/${locationCode}/${sinceLaunch}/${locationType}/${conferenceTitleId}`;
    return this.api.get(uri);
  }

  getEstatementsByLanguages(startDate, endDate): Observable<any> {
    const uri = `${this.apiController}/getEstatementsByLanguages/${startDate}/${endDate}`;
    return this.api.get(uri);
  }

  getEStatementsAccessed(startDate, endDate, locationType, code, byLang) : Observable<any> {
    const uri = `${this.apiController}/eStatementsAccessedByDate/${startDate}/${endDate}/${locationType}/${code}/${byLang}`;
    return this.api.get(uri);  }

    getEStatementsAccessedSinceLaunch(locationType,code, byLang): Observable<any> {
    const uri = `${this.apiController}/eStatementsAccessedSinceLaunch/${locationType}/${code}/${byLang}`;
    return this.api.get(uri);  }

  getAddedSummariesTemplates(startDate, endDate): Observable<any> {
    const uri = `${this.apiController}/SummariesAdded/${startDate}/${endDate}`;
    return this.api.get(uri);
  }

  getTotalNumberOfSummaries(startDate, endDate, locationCode,locationType,conferenceTitleId): Observable<any> {
    const uri = `${this.apiController}/TotalSummaries/${startDate}/${endDate}/${locationCode}/${locationType}/${conferenceTitleId}`;
    return this.api.get(uri);
  }

  getNumberOfSummariesByFilter(startDate,endDate,locationCode, reportSubType,locationType,conferenceTitleId): Observable<any> {
    const uri = `${this.apiController}/SummaryByUsers/${startDate}/${endDate}/${locationCode}/${reportSubType}/${locationType}/${conferenceTitleId}`;
    return this.api.get(uri);
  }

  getNumberOfSummariesUsed(startDate,endDate,locationCode,locationType,conferenceTitleId): Observable<any> {
    const uri = `${this.apiController}/SummariesUsed/${startDate}/${endDate}/${locationCode}/${locationType}/${conferenceTitleId}`;
    return this.api.get(uri);
  }


  getSummaryOrgans(startDate,endDate,locationCode,locationType,conferenceTitleId): Observable<any> {
    const uri = `${this.apiController}/SummaryOrgans/${startDate}/${endDate}/${locationCode}/${locationType}/${conferenceTitleId}`;
    return this.api.get(uri);
  }

  getNumberGIAnnouncements(locationCode, startDate, endDate,locationType): Observable<any> {
    const uri = `${this.apiController}/GetNumberGIAnnouncements/${startDate}/${endDate}/${locationType}/${locationCode}`;
    return this.api.get(uri);
  }

  gGetPDFDownloads(locationCode, startDate, endDate): Observable<any> {
    const uri = `${this.apiController}/GetNumberOfPDFDownloads/${startDate}/${endDate}/${locationCode}`;
    return this.api.get(uri);
  }

  getNumberOfICJAnnouncements(locationCode, startDate, endDate): Observable<any> {
    const uri = `${this.apiController}/GetNumberOfICJs/${startDate}/${endDate}/${locationCode}`;
    return this.api.get(uri);
  }
  getNumberOfOtherMeetings(startDate, endDate,locationType,conferenceTitleId, locationCode): Observable<any> {
    const uri = `${this.apiController}/GetNumberOfOtherMeetings/${startDate}/${endDate}/${locationType}/${conferenceTitleId}/${locationCode}`;
    return this.api.get(uri);
  }


  getMetingsAnnouncedPerMeetingType(locationCode, startDate, endDate,locationType,conferenceTitleId): Observable<any> {
    const uri = `${this.apiController}/GetNumberOfMeetingsPerCategory/${startDate}/${endDate}/${locationType}/${conferenceTitleId}/${locationCode}`;
    return this.api.get(uri);
  }

  GetNumberOfJournalsIssued(locationCode, startDate, endDate,locationType,conferenceTitleId): Observable<any> {
    const uri = `${this.apiController}/GetNumberOfJournalsIssued/${startDate}/${endDate}/${locationType}/${conferenceTitleId}/${locationCode}`;
    return this.api.get(uri);
  }

  GetTranslationPercentage(startDate, endDate, paramName) : Observable<any>{
    const uri = `${this.apiController}/GetTranslationPercentage/${startDate}/${endDate}/${paramName}`;
    return this.api.get(uri);
  }

  GetNumberOfAgendaCards(locationCode, startDate, endDate) :Observable<any> {
    const uri = `${this.apiController}/AgendaCards/${startDate}/${endDate}/${locationCode}`;
    return this.api.get(uri);
  }

  getLocations() {
    const uri = `${this.apiController}/GetLocations`;
    return this.api.get(uri);
  }

  getParameters(){
    const uri = `${this.apiController}/GetParameters`;
    return this.api.get(uri);
  }

  getMeetingCategoriesForDownload(startDate, endDate, locationCode,locationType,conferenceTitleId) : Observable<any>{
    const uri = `${this.apiController}/DownloadMeetingsPerCategory/${startDate}/${endDate}/${locationType}/${conferenceTitleId}/${locationCode}`;
    return this.api.get(uri);
  }

  getNumberOfWebCastsAnnounced(startDate, endDate, locationCode) : Observable<any>{
    const uri = `${this.apiController}/GetNumberOfWebCastAnnouncements/${startDate}/${endDate}/${locationCode}`;
    return this.api.get(uri);
  }

  getDutyStations() {
    const uri = `${this.apiController}/GetDutyStations`;
    return this.api.get(uri);
  }

  getConferenceLocations() {
    const uri = `${this.apiController}/GetConferenceLocations`;
    return this.api.get(uri);
  }

  getconferenceTitles() {
    const uri = `${this.apiController}/GetConferenceTitles`;
    return this.api.get(uri);
  }

  getconferenceSymbolsAndTitles() {
    const uri = `${this.apiController}/GetConferenceSymbolsTitles`;
    return this.api.get(uri);
  }

  getPrincipalOrgansList() {
    const uri = `${this.apiController}/PrincipalOrgans`;
    return this.api.get(uri);
  }

  getNumberOfConferences(startDate, endDate, locationCode, isServicingDutyStation) {
    const uri = `${this.apiController}/conferences/${startDate}/${endDate}/${locationCode}/${isServicingDutyStation}`;
    return this.api.get(uri);
  }

  getNumberOfConferencesByOrgan(startDate, endDate, organId) {
    const uri = `${this.apiController}/conferencesByOrgan/${startDate}/${endDate}/${organId}`;
    return this.api.get(uri);
  }


  getNumberOfConferencesSinceLaunch(startDate, endDate, locationCode) {
    const uri = `${this.apiController}/conferencesSinceLaunch/${startDate}/${endDate}/${locationCode}`;
    return this.api.get(uri);
  }
}
