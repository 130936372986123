import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilitiesApi } from '../api/utilities.api';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private utilitiesApi: UtilitiesApi) { }

  getVersion(): Observable<string> {
    return this.utilitiesApi.getVersion();

  }

  /* Users */

  changeDefaultLocation(location: string) {
    return this.utilitiesApi.changeDefaultLocation(location);
  }

  registerLogin() {
    return this.utilitiesApi.registerLogin();
  }
}
