<div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-body>
          <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
            <div class="bubble-wrap"><span class="speech-bubble"><b><i>OOPS!</i></b></span></div>  
            <small class="sub-title"><strong>{{note}}</strong></small>
            <!-- <div class="outerWrap">
              <div class="layer1"></div>
              <div class="layer2">                -->
                <h2 class="title"> <span class="k-icon k-i-warning colored-icon k-icon-64"></span>&nbsp;{{title}}</h2>
                              <h2 class="title">{{description}}</h2>
              <!-- </div>
            </div> -->
            <button nbButton fullWidth status="primary" (click)="onClick()"  type="button" class="home-button">
              {{buttonText}}&nbsp; <nb-icon *ngIf="buttonIcon" icon="{{buttonIcon}}"></nb-icon>
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>