import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';


@Injectable()
export class DropDownApi {

  private readonly apiController: string = 'dropdown/';

  constructor(private api: HttpService) { }

  public getData(queryStr: string): Observable<any> {
    let uri = `${this.apiController}${queryStr}`;
    return this.api.get(uri);
  }

  public getDataById(queryStr: string): Observable<any> {   
    return this.api.get(`${queryStr}`);
  }

  public getLocations(queryStr: string): Observable<any> {   
    return this.api.get(`${queryStr}`);
  }

  public getABCFilter(queryStr: string): Observable<any> {   
    return this.api.get(`${queryStr}`);
  }


}
