import { Component } from '@angular/core';

@Component({
  selector: 'ngx-jcms-layout',
  styleUrls: ['./jcms.layout.scss'],
  template: `
     <nb-layout windowMode>
      <nb-layout-header fixed>
         <ngx-jcms-header></ngx-jcms-header>     
      </nb-layout-header>

     <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
       <ngx-jcms-footer></ngx-jcms-footer>       
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class JCMSLayoutComponent {}
