import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { Observable, forkJoin, BehaviorSubject, of, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DropDownApi } from '../api/dropdown.api';

@Injectable()
export class DropDownService extends BehaviorSubject<any[]> {

  private readonly organType: string = 'organs';
  private readonly gaSessrionType: string = 'gasessions';
  private readonly agendaType: string = 'agendumtypes';
  private readonly prefixes: string = "prefixes";
  private readonly funtions: string = "functions";
  private readonly honorifics: string = "Honorifics";
  private readonly incidents: string = "Incidents";
  private readonly categories: string = "categories";
  private readonly links: string = "tags/Link";
  private readonly rooms: string = "rooms";
  private readonly odsDocument = "ODSDocument";
  private readonly principalorgans: string = "principalorgans";
  private readonly ngos: string = "NGOs";
  private readonly countries = "Countries";
  private readonly regions = "Regions";
  private readonly persons = "Persons";
  private readonly gender = "Gender";
  private readonly meetingOdsSymbols = "Symbols";
  private readonly meetingAgendaItems = "AgendaItems";
  private readonly groupingSeperators = "GroupingSeperators";

  public loading: boolean;
  public id: string;
  public data: any[] = [];
  public state: State = {
    skip: 0,
    take: 5,
    filter: { filters: [], logic: 'or' },
    group: [],
    sort: []
  };

  constructor(private api: DropDownApi) {
    super([]);
  }

  getList(type: string): Observable<any> {
    return this.api.getData(type);
  }

  getOrgans(): Observable<any> {
    return this.api.getData(this.organType);
  }

  getPrefixes(): Observable<any> {
    return this.api.getData(this.prefixes);
  }

  getGASessions(): Observable<any> {
    return this.api.getData(this.gaSessrionType);
  }

  getFuntions(): Observable<any> {
    return this.api.getData(this.funtions);
  }

  getAgendaType(): Observable<any> {
    return this.api.getData(this.agendaType);
  }

  getIncidents(): Observable<any> {
    return this.api.getData(this.incidents);
  }

  getCategories(): Observable<any> {
    return this.api.getData(this.categories);
  }

  getLinks(): Observable<any> {
    return this.api.getData(this.links);
  }

  getRoomsByLocation(locationCode: string) {
    const uri = "kendo/rooms/" + (locationCode);
    return this.api.getData(uri);
  }

  getDataById(type, id) {
    return this.api.getDataById(type + '/' + id);
  }

  getContactsByOrgan(organId: string) {
    const uri = "kendo/contacts" + ((organId) ? '/' + organId : '')
    return this.api.getData(uri);
  }

  getOdsTitle(symbols) {
    const uri = this.odsDocument + "/" + symbols;
    return this.api.getData(uri);
  }

  getMultipletDataByIds(type, ids: string[]): Observable<any> {

    if (typeof ids === 'string') {
      return forkJoin([this.getDataById(type, ids)]);
    }
    else if (ids) {
      let observables = ids.map((id: string) => {
        return this.getDataById(type, id);
      });

      return forkJoin(observables);
    }
    else { return null; }
  };

  getPrincipalOrgans(): Observable<any> {
    return this.api.getData(this.principalorgans);
  }

  getLocations() {
    const url = "DropDown/Kendo/Location";
    return this.api.getLocations(url);
  }


  getABCFilter(dbType, ABCcolumn) {
    const url = `dropdown/kendo/ABCFilter/${dbType}/${ABCcolumn}`;
    return this.api.getABCFilter(url);
  }

  public read() {
    if (this.data?.length) {
      return super.next(this.data);
    }

    this.fetch()
      .pipe(
        tap(data => {
          this.data = data;
        })
      )
      .subscribe({
        next: (data) => { super.next(data); },
        error: (error) => { console.log(error); }
      });
  }

  public fetch(): Observable<any> {
    this.loading = true;
    let stateValue = <any>this.state;

    const queryStr = `${toDataSourceRequestString(stateValue)}`;
    const hasGroups = this.state.group && this.state.group.length;

    const url = `kendo/${this.organType}` + '?' + queryStr;

    return this.api.getData(url).pipe(
      // Process the response
      map(({ data, total }: GridDataResult): GridDataResult => {
        data = data.map(item => {
          return item;
        });
        return {
          data: hasGroups ? translateDataSourceResultGroups(data) : data,
          total: total
        };
      }
      ),
      tap(() => { this.loading = false; }));
  }

  getCountries(): Observable<any> {
    return this.api.getData(this.countries);
  }

  getNgos(): Observable<any> {
    return this.api.getData(this.ngos);
  }

  getRegions(): Observable<any> {
    return this.api.getData(this.regions);
  }

  getHonorifics(): Observable<any> {
    return this.api.getData(this.honorifics);
  }

  getPersons(): Observable<any> {
    return this.api.getData(this.persons);
  }

  getGenderList(): Observable<any> {
    return this.api.getData(this.gender);
  }

  getMeetingOdsSymbols(meetingId: string): Observable<any> {
    return this.api.getData(`${this.meetingOdsSymbols}/${meetingId}`);
  }

  getMeetingAgendaItems(meetingId: string): Observable<any> {
    return this.api.getData(`${this.meetingAgendaItems}/${meetingId}`);
  }

  getGroupingSeperators(locationCode?: string): Observable<any>{
    let uri;
    if(typeof locationCode == "undefined")
    uri = this.groupingSeperators ;
    else
    uri = this.groupingSeperators + "/" + locationCode;
    return this.api.getData(uri);
  }
}


