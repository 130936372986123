import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { User } from '../../../@core/interfaces/user';
import { UserStateService } from '../../../identity/user-state.service';
import { InitUserService } from '../../services/init-user.service';
import { NbAuthService } from '@nebular/auth';


@Component({
  selector: 'ngx-jcms-header',
  styleUrls: ['./jcms-header.component.scss'],
  templateUrl: './jcms-header.component.html',
})
export class JCMSHeaderComponent implements OnInit, OnDestroy {

  env = (environment.env != "PROD") ? environment.env : '';
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  userMenu = this.getMenuItems();

  user$: Observable<User> = this.userState.$user;

  langMapping = {
    "en": "english",
    "fr": "french",
    "ar": "arabic",
    "zh": "chinese",
    "ru": "russian",
    "es": "spanish"
  };

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userState: UserStateService,
    private authService: NbAuthService,
    private initUserService: InitUserService) {
  }


  ngOnInit() {
    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
      )
      .subscribe((item: any) => {
        if (item?.item?.data?.id === 'refresh') {
          console.log("refresh clicked..");
          this.initUserService.initCurrentUser()
            .pipe(
              take(1),
              mergeMap(data => {
                return this.authService.authenticate('identity');
              })
            ).subscribe(() => {

              // TO DO
              // this.geUsertLocations();

            });
        }
      });
  }

  getMenuItems() {
    return [
      { title: 'Refresh Roles', icon: 'refresh', data: { id: 'refresh' } },
      { title: 'Profile', link: '/pages/profile', icon: 'person' },
      { title: 'Log out', link: '/identity/logout', icon: 'unlock' }
    ];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

}
