import { Injectable } from '@angular/core';
import { StateService } from '../shared/state.service';
import { Observable } from 'rxjs';
import { User } from '../@core/interfaces/user';

interface UserState {
  count: number;
  user: User;
}

const initialState: UserState = {
  count: 42,
  user: JSON.parse(localStorage.getItem('currentUser'))
}

@Injectable({
  providedIn: 'root'
})
export class UserStateService extends StateService<UserState>{

  $count: Observable<number> = this.select(state => state.count);

  $user: Observable<User> = this.select(state => state.user);

  constructor() {
    super(initialState)
  }

  setUser(user:User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.setState(
        {
            user: user
        });
  }

  increment() {
    this.setState({count: this.state.count + 1})
  }

  decrement() {
    this.setState({count: this.state.count - 1})
  }
}
