import { JcmsUIConstants } from "../@core/backend/services/constant"


export const crudActions = {
  new: 'new',
  edit: 'edit',
  clone: 'clone',
  delete: 'delete',
  view: 'view',
  import: 'import'
}


export const AccessControlList = {
  [JcmsUIConstants.userRoles.JournalTechnicalAdmin]: {
    [crudActions.new]: '*',
    [crudActions.edit]: '*',
    [crudActions.clone]: '*',
    [crudActions.delete]: '*',
    [crudActions.import]: ['documents', 'tags'],
    [crudActions.view]: ["dailylist", "agendacards", "pdf", "statementlabel", "agendasource", "web", "journalsymbols", "symbols", "holidays", "agendadictionary", "agendamapping", "pdf", "field", "import", "generalinfo", "room", "uistyleswitcher", "organigram", "title", "signaturetitle", "import", "documents", "tag", "theme", "room", "person", "organprofile", "organ", "ngo", "honorific", "function", "region", "city", "country", "agenda", "meetings", "session", "summary", "details", "agenda", "other", "informal", "official", "meeting", "organs", "contacts", "inbox", "otherevents", "signatures", "import", "incident", "conferences", "gdocuments", "specialcharacters", "parameter", "location", "timezone", "emailconfigs", "icj"],
  },
  [JcmsUIConstants.userRoles.Journal_Reports_Manager]: {
    [crudActions.new]: ["inbox", "contacts", "meetings", "official", "informal", "other", "agenda", "details", "summary", "session", "meeting", "agenda", "country", "city", "region", "function", "honorific", "ngo", "organprofile", "person", "room", "tag", "theme", "title", "organigram", "uistyleswitcher", "web", "pdf", "agendacards", "signaturetitle", "agendasource", "incident", "conferences", "specialcharacters", "location", "timezone", "icj"],
    [crudActions.edit]: ["inbox", "contacts", "meetings", "official", "informal", "other", "agenda", "details", "summary", "session", "meeting", "agenda", "country", "city", "region", "function", "honorific", "ngo", "organ", "organprofile", "person", "room", "tag", "theme", "title", "organigram", "uistyleswitcher", "web", "pdf", "statementlabel", "agendacards", "signaturetitle", "agendasource", "incident", "conferences", "specialcharacters", "location", "timezone", "icj"],
    [crudActions.clone]: ["inbox", "contacts", "meetings", "official", "informal", "other", "agenda", "details", "summary", "session", "meeting", "agenda", "country", "city", "region", "function", "honorific", "ngo", "organprofile", "person", "room", "tag", "theme", "title", "organigram", "uistyleswitcher", "agendacards", "signaturetitle", "agendasource", "incident", "conferences", "specialcharacters", "location", "timezone", "icj"],
    [crudActions.delete]: ["inbox", "contacts", "meetings", "official", "informal", "other", "agenda", "details", "summary", "session", "meeting", "agenda", "country", "city", "region", "function", "honorific", "ngo", "organprofile", "person", "tag", "theme", "title", "organigram", "uistyleswitcher", "agendacards", "signaturetitle", "agendasource", "incident", "conferences", "specialcharacters", "icj"],
    [crudActions.view]: ["inbox", "contacts", "meetings", "official", "informal", "other", "agenda", "details", "summary", "session", "meeting", "agenda", "country", "city", "region", "function", "honorific", "ngo", "organ", "organprofile", "person", "room", "tag", "theme", "title", "organigram", "uistyleswitcher", "web", "pdf", "statementlabel", "agendacards", "signaturetitle", "agendasource", "incident", "conferences", "organs", "import", "specialcharacters", "parameter", "location", "timezone", "icj"],
  },
}
