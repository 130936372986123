import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MenusApi } from '../api/menus.api';

@Injectable()
export class MenusService extends BehaviorSubject<any[]>{

  private _draftStatusSubject = new Subject();
  draftStatus = <Observable<boolean>> this._draftStatusSubject;

  constructor(private api: MenusApi) {
    super([]);
  }

  getMenus(): Observable<any> {
    return this.api.getMenus();
  }

  getDmDraftCount(): Observable<any> {
    return this.api.getDmDraftCount();
  }

  updateDraftStaus(){
    this._draftStatusSubject.next(true);
  }

}
