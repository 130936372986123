
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UsersApi } from '../api/users.api';
import { NbAuthService } from '@nebular/auth';
import { switchMap, map } from 'rxjs/operators';
import { JcmsUIConstants } from "./constant";
import { User } from '../../interfaces/user';

@Injectable()
export class UsersService {

  constructor(private api: UsersApi, private authService: NbAuthService) {

  }

  getCurrentUser(): Observable<User> {
    return this.authService.isAuthenticated()
      .pipe(
        switchMap(authenticated => {
          return authenticated ? this.api.getCurrent() : of(null);
        }),
        map(u => {
          if (u && !u.setting) {
            u.setting = {};
          }

          //location
          if (u && u.location) {
            var loc = u.location;
            u.location = (typeof loc === "string") ? new Array(loc) : loc;

          }
          else {
            u.location = new Array(JcmsUIConstants.defaultLocation);
          }

          //duty station
          if (u && u.dutystation) {
            var ds = u.dutystation;
            u.dutystation = (typeof ds === "string") ? new Array(ds) : ds;
          }
          else {
            u.dutystation = new Array(JcmsUIConstants.defaultLocation);
          }

          if (u && !u.fullName) {
            u.fullName = `${u.given_name} ${u.family_name}`;
          }

          if (u && !u.apps && u.AppDetails) {
            // console.log(`${u.AppDetails}`);
            u.apps = JSON.parse(`${u.AppDetails}`);
          }

          return u;
        }));
  }

  getLocalUser(): User {
    let user: User = JSON.parse(localStorage.getItem('currentUser'));

    return user;
  }

}


