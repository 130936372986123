import { Component, OnInit } from '@angular/core';
 import { UtilitiesService } from '../../../@core/backend/services/utilities.service';


@Component({
  selector: 'ngx-jcms-footer',
  styleUrls: ['./jcms-footer.component.scss'],
  template: `
   <span class="footer-text">
         {{uiVersion}}
    </span>
  `,
})
export class JCMSFooterComponent implements OnInit {

  uiVersion:any;

  constructor(private utils:UtilitiesService){}

  ngOnInit(): void {
    this.utils.getVersion().subscribe((version: any) => {
      this.uiVersion = version;
    });
  }

}
