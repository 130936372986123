import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
  {
    path: 'identity',
    loadChildren: () => import('./identity/identity.module').then(m => m.IdentityModule),
  },
  {
    canActivate: [AuthGuard],
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: 'EStatements', loadChildren: () => import('./pages/estatements/estatements.module').then(m => m.EStatementsModule) },
  { path: 'agenda-cards', loadChildren: () => import('./pages/agenda-cards/agenda-cards.module').then(m => m.AgendaCardsModule) },
  { path: 'summaries', loadChildren: () => import('./pages/summaries/summaries.module').then(m => m.SummariesModule) },
  { path: 'conferences', loadChildren: () => import('./pages/conferences/conferences.module').then(m => m.ConferencesModule) },
    { path: '**', redirectTo: 'pages/404' },
];

const config: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
