
const keyPrefix = "kendo-grid-sate-";

function clearKendoGridState ()   {

  // remove previous keys
  Object.keys(sessionStorage)
    .filter(function (key) { return key.startsWith(keyPrefix); })
    .forEach(function (key) {
      sessionStorage.removeItem(key);
    });
}

export { keyPrefix, clearKendoGridState}

