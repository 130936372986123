
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
 import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

const versionText = '\u00A9 2018-yyyy United Nations (Journal Reports vxxx)';

@Injectable()
export class UtilitiesApi {
  private readonly apiController: string = 'assets/version.json';
  private readonly holidaysApiController: string = 'Utils/holidays/';
  private readonly symbolsApiController: string = 'Utils/symbol/';


  constructor(private api: HttpService) { }

  getVersion(): Observable<any> {
    return this.api.getAbsolute(`${this.apiController}`)
      .pipe(map(data => {
        return this.processVersion(data.ReleaseNumber);
      }));
  }

  processVersion(txt: any) {
    const year: string = txt.split('.')[1].substring(0, 4);
    let version = versionText.replace('yyyy', year).replace('xxx', txt);
    return version;
  }

  /*** Holidays ***/

  getHolidays(year: any) {
    let endpoint = 'all';
    return this.api.get(`${this.holidaysApiController}${endpoint}/${year}`);
  }

  getHoliday(id: string) {
    return this.api.get(`${this.holidaysApiController}/${id}`)
  }

  public create(payload: any) {
    let uri = `${this.holidaysApiController}`;
    return this.api.post(uri, payload);
  }

  public update(id: string, payload: any,) {
    let uri = `${this.holidaysApiController}`;
    return this.api.put(`${uri}${id}`, payload);
  }

  public getAllYears(isForImport: boolean) {
    let uri = `${this.holidaysApiController}years/${isForImport}`;
    return this.api.get(uri);
  }

  public downloadHolidays(year: string) {
    let uri = `${this.holidaysApiController}download/${year}`;
    return this.api.get(uri, { responseType: 'blob' });
  }

  public importHolidays(toYear: string, fromYear: string) {
    let uri = `${this.holidaysApiController}import/${toYear}/${fromYear}`;
    return this.api.post(uri, toYear, fromYear);
  }

  deleteAllHolidays(year: string) {
    let uri = `${this.holidaysApiController}all/${year}`;
    return this.api.delete(uri);
  }

  deleteHoliday(id: string) {
    let uri = `${this.holidaysApiController}${id}`;
    return this.api.delete(uri);
  }

  /**Symbols */
  getSymbolSummary() {
    let uri = `${this.symbolsApiController}`;
    return this.api.get(uri);
  }

  generateSymbols(year: string, isVisible: boolean) {
    let uri = `${this.symbolsApiController}generate/${year}/${isVisible}`;
    return this.api.post(uri, null);
  }

  updateSymbols(payload: any, isVisible: boolean) {
    let uri = `${this.symbolsApiController}toggle/${isVisible}`;
    return this.api.post(uri, payload);
  }

  /* Users */

  changeDefaultLocation(location: string) {
    return this.api.put(`utils/updateuser/${location}`, null);
  }

  registerLogin() {
    return this.api.put(`utils/registerlogin/${environment.clientId}`, null);
  }

  /***GDOC Data */

  getDocumentBySymbols(location: string, symbol: string, dualSymbol: string) {
    var queryParams = { symbol: symbol, dualSymbol: dualSymbol };
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('symbol', symbol);
    params = params.append('dualSymbol', dualSymbol);
    //let uri =`utils/gDocData/${location}` + "?" + query;
    let uri = `utils/gDocData/${location}`;
    return this.api.get(uri, { params: params });
  }


  getDocumentsByReleaseDate(location: string, dateFrom: string, dateTo: string) {
    // var queryParams =
    let uri = `utils/gDocData/${location}/${dateFrom}/${dateTo}`;
    return this.api.get(uri);
  }

  /***Email configuration */
  getAllEmailCodes() {
    let uri = `utils/emailcodes`;
    return this.api.get(uri);
  }

  public getAllEmailCodesData(queryStr: string): Observable<any> {

    return this.api.get(queryStr);
  }

  public getEmailCodeById(id: string): Observable<any> {

    return this.api.get(`utils/emailcodes/${id}`)
  }

  public updateEmailCodeById(id: string, payload: any): Observable<any> {

    return this.api.put(`utils/emailcodes/${id}`, payload);
  }


}
