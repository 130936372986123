<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" title="Toggle Sidebar">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a title="Home" class="logo" [routerLink]="['/pages']">
      <img alt="Journal Reports logo" height="50" src="assets/images/JournalReports.png" />
    </a>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect x="0" y="0" width="24" height="24" fill="#009ddc" />
      <circle cx="12" cy="12" r="11" stroke="white" stroke-width="2" fill="none" />
      <rect x="5" y="5" width="14" height="14" fill="white" />
      <rect x="9" y="5" width="1" height="14" fill="#009ddc" />
      <rect x="14" y="5" width="1" height="14" fill="#009ddc" />
      <rect x="5" y="9" width="14" height="1" fill="#009ddc" />
      <rect x="5" y="14" width="14" height="1" fill="#009ddc" />
    </svg> -->
    <a title="Home" class="heading" [routerLink]="['/pages']">Journal Reports <span *ngIf="env">[{{env}}]</span></a>
  </div>
</div>

<div class="header-container" *ngIf="user$ | async as userobj">
  <span class="k-icon k-i-marker-pin-target k-icon-size"></span> <span>New york</span>
  <nb-actions size="small">
    <!-- <nb-action class="user-action" *nbIsGranted="['view', 'user']">                -->
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="user-context-menu" [onlyPicture]="userPictureOnly"
        size="large" class="user-large" [name]="userobj?.fullName"
        title="{{userobj?.language == 'al'?'All languages':langMapping[userobj?.language]|titlecase}}">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
